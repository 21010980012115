import React, { Component, Fragment } from 'react'
import ImagePreferred from 'shared/assets/icons/prefer.svg'
import ImageDoNotUse from 'shared/assets/icons/no.svg'
import ImageRestrict from 'shared/assets/icons/restrict.svg'
import ImageExisting from 'shared/assets/icons/existing.svg'
import ImageNonRecruit from 'shared/assets/icons/non-recruit.svg'
import ImageAttachments from 'shared/assets/icons/file.svg'
import ImagePrivate from 'shared/assets/icons/private.svg'
import ImageDiversity from 'shared/assets/icons/diversity.svg'
import ImageQuality from 'shared/assets/icons/quality.svg'
import ImageSecurity from 'shared/assets/icons/security.svg'
import ImageSustainability from 'shared/assets/icons/sustainable.svg'
import ImageSanctionedCountry from 'shared/assets/icons/banned-country.svg'
import ImageSanctionedCompany from 'shared/assets/icons/banned-company.svg'
import ImageDiversityProgram from 'shared/assets/icons/diversity-program.svg'
import ImageSustainabilitySurvey from 'shared/assets/icons/sus-survey-darkteal.svg'
import ImageFood from 'shared/assets/icons/foods.svg'
import Tooltip from 'shared/components/Tooltip'
import ImageExpired from 'shared/assets/icons/expired.svg'
import ImageVerified from 'shared/assets/icons/verified.svg'
import { NavLink as Link } from 'react-router-dom'
import moment from 'moment'
import startCase from 'lodash.startcase'
import certificationCategories from 'shared/models/CertificationCategories'
import { injectIntl, defineMessages, IntlShape } from 'react-intl'
import { Map, List, RecordOf } from 'immutable'
import Certification from 'shared/models/Certification'
import camelCase from 'lodash.camelcase'

const today = new Date()

const messages = defineMessages({
  preferred: {
    id: 'SupplierAttributes.Preferred',
    defaultMessage: 'Preferred'
  },
  existing: {
    id: 'SupplierAttributes.Existing',
    defaultMessage: 'Existing'
  },
  restricted: {
    id: 'SupplierAttributes.Restricted',
    defaultMessage: 'Restricted'
  },
  nonRecruit: {
    id: 'SupplierAttributes.NonRecruit',
    defaultMessage: 'Non Recruit'
  },
  doNotUse: {
    id: 'SupplierAttributes.DoNotUse',
    defaultMessage: 'Do Not Use'
  },
  private: {
    id: 'SupplierAttributes.Private',
    defaultMessage: 'Private'
  },
  sanctionedCompany: {
    id: 'SupplierAttributes.SanctionedCompany',
    defaultMessage:
      "This company's name shows up on the list of internationally sanctioned companies"
  },
  sanctionedCountry: {
    id: 'SupplierAttributes.SanctionedCountryLists',
    defaultMessage:
      'This company has an address in a country that is on the list of internationally sanctioned countries: {data}'
  },
  attachment: {
    id: 'SupplierAttributes.Attachment',
    defaultMessage: 'Attachment'
  },
  diversityProgram: {
    id: 'SupplierAttributes.DiversityProgram',
    defaultMessage: 'Supplier Diversity Program'
  },
  diversityProgramInfo: {
    id: 'SupplierAttributes.DiversityProgramInfo',
    defaultMessage:
      'Prioritizing spend with small and/or diverse suppliers and can provide spend with these suppliers.'
  },
  sustainabilitySurvey: {
    id: 'SupplierAttributes.SustainabilitySurvey',
    defaultMessage: 'Sustainability Survey Status'
  },
  sustainabilitySurveyStatus: {
    id: 'SupplierAttributes.sustainabilitySurveyStatus',
    defaultMessage: 'Completed'
  },
  diversity: {
    id: 'SupplierAttributes.Diversity',
    defaultMessage: 'Diversity'
  },
  quality: {
    id: 'SupplierAttributes.Quality',
    defaultMessage: 'Quality'
  },
  security: {
    id: 'SupplierAttributes.Security',
    defaultMessage: 'Information Security'
  },
  sustainability: {
    id: 'SupplierAttributes.Sustainability',
    defaultMessage: 'Social Responsibility and Sustainability'
  },
  certification: {
    id: 'SupplierAttributes.Certification',
    defaultMessage: 'Certification'
  },
  incidence: {
    id: 'SupplierAttributes.Incidence',
    defaultMessage: 'Incidence'
  },
  audit: {
    id: 'SupplierAttributes.Audit',
    defaultMessage: 'Audit'
  },
  contract: {
    id: 'SupplierAttributes.Contract',
    defaultMessage: 'Contract'
  },
  other: {
    id: 'SupplierAttributes.Other',
    defaultMessage: 'Other'
  }
})

const styles = {
  icon: 'w1 h1 dib v-mid ml1',
  clickableIcon: 'w1 h1 dib v-mid ml1 pointer',
  externalLink: 'gray pointer hover-teal'
}

type Props = {
  disableActions?: boolean
  isPreferred?: boolean
  isDoNotUse?: boolean
  isRestricted?: boolean
  isExisting?: boolean
  isNonRecruit?: boolean
  isPrivate?: boolean
  isSanctionedCountry?: boolean
  isSanctionedCompany?: boolean
  attachments?: Array<{
    createdBy?: string
    createdDate?: string
    documentFileUrl?: string
    expirationDate?: string
    type?: string
  }>
  certifications?: List<[string, List<RecordOf<Certification>>]>
  linkToDiversity?: string
  linkToAttachment?: string
  linkToRelationship?: string
  sanctionedCountryList?: List<string>
  diversityProgram?: boolean
  sustainabilitySurvey?: boolean
  supplierName?: string
  website?: string
  intl: IntlShape
  attachmentTypes?: Map<string, Map<string, string>>
  relationshipTypes?: Map<string, Map<string, string>>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      subCategory: string
      timeStamp: string
    }>
  >
  isSurveyCompleted?: boolean
}

export class SupplierAttributes extends Component<Props> {
  state = {
    showPopover: {},
    anchorEl: undefined
  }

  handleClick = e => {
    e.preventDefault()
    this.setState({
      anchorEl: e.currentTarget,
      showPopover: { [e.currentTarget.name]: true }
    })
  }

  handlePopoverClose = () => {
    this.setState({
      showPopover: {}
    })
  }
  checkValidation = (subCategory, timeStamp) => {
    const { validations } = this.props

    let verified =
      validations &&
      validations.find(
        el =>
          el.getIn([0, 'subCategory']) === subCategory &&
          el.getIn([0, 'timeStamp']) === timeStamp
      )
    return verified && verified.getIn([0, 'confirmed'])
  }
  renderCertification = (
    icon,
    category: string,
    certifications: List<RecordOf<Certification>>
  ) => {
    const { linkToDiversity, intl, supplierName, website } = this.props

    return (
      <Tooltip
        title={
          <Fragment>
            <div className='fw6'>
              {messages[category]
                ? intl.formatMessage(messages[category])
                : startCase(category)}
              :
            </div>
            {certifications
              .reduce((results, cert) => {
                if (results.has(cert.get('subCategory'))) {
                  return results.updateIn(
                    [cert.get('subCategory')],
                    existingCert => {
                      const existingCertExpiration = existingCert.get(
                        'certExpiration'
                      )
                      const certExpiration = cert.get('certExpiration')

                      return (certExpiration &&
                        existingCertExpiration &&
                        new Date(existingCertExpiration) <
                          new Date(certExpiration)) ||
                        (cert.get('certAgency') &&
                          !existingCert.get('certAgency'))
                        ? cert
                        : existingCert
                    }
                  )
                } else {
                  return results.set(cert.get('subCategory'), cert)
                }
              }, Map({}))
              .toList()
              .map((certification: RecordOf<Certification>, index: number) => {
                const verified = this.checkValidation(
                  certification.get('subCategory'),
                  certification.get('timeStamp')
                )
                const certExpiration = certification.get('certExpiration')
                const timezoneOffset = new Date().getTimezoneOffset()
                const expirationDate =
                  certExpiration && typeof certExpiration === 'string'
                    ? moment(new Date(certExpiration))
                        .add(timezoneOffset, 'm')
                        .toDate()
                    : certExpiration
                const expired =
                  expirationDate &&
                  expirationDate.setHours(23, 59, 59, 99) <
                    today.setHours(0, 0, 0, 0)

                return (
                  <div
                    key={`${index}-cert-${certification.get(
                      'category'
                    )}${certification.get('subCategory')}`}
                  >
                    {certificationCategories[certification.get('category')] &&
                      certificationCategories[certification.get('category')]
                        .subCategories[certification.get('subCategory')] && (
                        <span className='dib v-mid'>
                          {intl.formatMessage(
                            certificationCategories[
                              certification.get('category')
                            ].subCategories[certification.get('subCategory')]
                          )}
                        </span>
                      )}
                    {verified && (
                      <img
                        src={ImageVerified}
                        alt='Verified'
                        className={styles.icon}
                      />
                    )}
                    {expired && (
                      <img
                        src={ImageExpired}
                        alt='Expired'
                        className={styles.icon}
                      />
                    )}
                  </div>
                )
              })}
          </Fragment>
        }
      >
        {linkToDiversity ? (
          <Link
            to={linkToDiversity}
            arial-label={`Link to ${supplierName} (${website}) ${category} certifications`}
          >
            <img
              src={icon}
              alt={`${supplierName}'s (${website}) ${startCase(
                certifications.getIn([0, 'category'])
              )} icon`}
              className={styles.clickableIcon}
            />
          </Link>
        ) : (
          <img
            src={icon}
            alt={`${supplierName}'s (${website}) ${startCase(
              certifications.getIn([0, 'category'])
            )} icon`}
            className={styles.icon}
          />
        )}
      </Tooltip>
    )
  }

  renderAttachment = (icon, attachments) => {
    const {
      linkToAttachment,
      intl,
      attachmentTypes,
      disableActions,
      supplierName,
      website
    } = this.props

    return (
      <Tooltip
        title={
          <Fragment>
            <div className='fw6'>
              {intl.formatMessage(messages.attachment)}:
            </div>
            {attachments.map((attachment, index) => {
              const attachExpiration =
                attachment.expiry || attachment.expirationDate
              const expirationDate =
                attachExpiration && typeof attachExpiration === 'string'
                  ? new Date(attachExpiration)
                  : attachExpiration
              const expired =
                expirationDate &&
                expirationDate.setHours(23, 59, 59, 99) <
                  today.setHours(0, 0, 0, 0)
              const type =
                attachmentTypes &&
                attachmentTypes.getIn([camelCase(attachment.type), 'label'])
              const tooltipLabel =
                type === attachment.type && messages[camelCase(attachment.type)]
                  ? intl.formatMessage(messages[camelCase(attachment.type)])
                  : type
              return (
                <div key={`attach-${tooltipLabel}-${index}`}>
                  <span className='dib v-mid'>{tooltipLabel}</span>
                  {attachment.documentFileUrl && (
                    <img
                      src={ImageAttachments}
                      alt='Attachment'
                      className={styles.icon}
                    />
                  )}
                  {expired && (
                    <img
                      src={ImageExpired}
                      alt='Expired'
                      className={styles.icon}
                    />
                  )}
                </div>
              )
            })}
          </Fragment>
        }
      >
        {!disableActions && linkToAttachment ? (
          <Link
            to={linkToAttachment}
            aria-label={`link to ${supplierName} (${website}) attachments`}
          >
            <img src={icon} alt='attachment' className={styles.clickableIcon} />
          </Link>
        ) : (
          <img src={icon} alt='attachment' className={styles.icon} />
        )}
      </Tooltip>
    )
  }

  renderAttribute = (icon, attribute, data?) => {
    const {
      linkToRelationship,
      relationshipTypes,
      intl,
      disableActions,
      supplierName,
      website
    } = this.props
    const value =
      relationshipTypes && relationshipTypes.has(attribute)
        ? relationshipTypes.getIn([attribute, 'label'])
        : attribute
    const tooltipLabel =
      camelCase(value) === attribute && messages[attribute]
        ? data
          ? intl.formatMessage(messages[attribute], data)
          : intl.formatMessage(messages[attribute])
        : value
    return (
      <Tooltip
        title={
          !data
            ? `${tooltipLabel} relationship with ${supplierName}`
            : tooltipLabel
        }
      >
        {!disableActions && linkToRelationship ? (
          <Link
            to={linkToRelationship}
            arial-label={`Link to relationship with ${supplierName} (${website})`}
          >
            <img
              src={icon}
              alt={`${tooltipLabel} relationship with ${supplierName} (${website})`}
              className={styles.clickableIcon}
            />
          </Link>
        ) : (
          <img
            src={icon}
            alt={`${tooltipLabel} relationship with ${supplierName}`}
            className={styles.icon}
          />
        )}
      </Tooltip>
    )
  }

  render() {
    const {
      isExisting,
      isPreferred,
      isRestricted,
      isPrivate,
      isNonRecruit,
      isDoNotUse,
      attachments,
      certifications,
      isSanctionedCompany,
      isSanctionedCountry,
      sanctionedCountryList,
      diversityProgram,
      intl,
      isSurveyCompleted
    } = this.props
    const sanctionedCountries =
      sanctionedCountryList && sanctionedCountryList.join(', ')

    const diversityCertifications = getCertificationsByCategory(
      certifications,
      'diversity'
    )

    const qualityCertifications = getCertificationsByCategory(
      certifications,
      'quality'
    )

    const securityCertifications = getCertificationsByCategory(
      certifications,
      'security'
    )

    const sustainabilityCertifications = getCertificationsByCategory(
      certifications,
      'sustainability'
    )

    const foodCertifications = getCertificationsByCategory(
      certifications,
      'food'
    )

    return (
      <div className='dib'>
        {isSanctionedCompany &&
          this.renderAttribute(ImageSanctionedCompany, 'sanctionedCompany')}
        {isSanctionedCountry &&
          this.renderAttribute(ImageSanctionedCountry, 'sanctionedCountry', {
            data: sanctionedCountries
          })}
        {isExisting && this.renderAttribute(ImageExisting, 'existing')}
        {isPreferred && this.renderAttribute(ImagePreferred, 'preferred')}
        {isRestricted && this.renderAttribute(ImageRestrict, 'restricted')}
        {isNonRecruit && this.renderAttribute(ImageNonRecruit, 'nonRecruit')}
        {isDoNotUse && this.renderAttribute(ImageDoNotUse, 'doNotUse')}
        {isPrivate && this.renderAttribute(ImagePrivate, 'private')}
        {diversityCertifications &&
          diversityCertifications.size > 0 &&
          this.renderCertification(
            ImageDiversity,
            'diversity',
            diversityCertifications
          )}
        {qualityCertifications &&
          qualityCertifications.size > 0 &&
          this.renderCertification(
            ImageQuality,
            'quality',
            qualityCertifications
          )}
        {securityCertifications &&
          securityCertifications.size > 0 &&
          this.renderCertification(
            ImageSecurity,
            'security',
            securityCertifications
          )}
        {sustainabilityCertifications &&
          sustainabilityCertifications.size > 0 &&
          this.renderCertification(
            ImageSustainability,
            'sustainability',
            sustainabilityCertifications
          )}
        {foodCertifications &&
          foodCertifications.size > 0 &&
          this.renderCertification(ImageFood, 'food', foodCertifications)}
        {diversityProgram && (
          <Tooltip
            title={
              <>
                <div className='fw6'>
                  {intl.formatMessage(messages.diversityProgram)}:
                </div>
                {intl.formatMessage(messages.diversityProgramInfo)}
              </>
            }
          >
            <img
              src={ImageDiversityProgram}
              alt='Supplier Diversity Program'
              className={styles.icon}
              tabIndex={0}
            />
          </Tooltip>
        )}
        {attachments &&
          attachments.length > 0 &&
          this.renderAttachment(ImageAttachments, attachments)}
        {isSurveyCompleted && (
          <Tooltip
            title={
              <>
                <div className='fw6'>
                  {intl.formatMessage(messages.sustainabilitySurvey)}:
                </div>
                {intl.formatMessage(messages.sustainabilitySurveyStatus)}
              </>
            }
          >
            <img
              src={ImageSustainabilitySurvey}
              alt='Sustainability Survey'
              className={styles.icon}
              tabIndex={0}
            />
          </Tooltip>
        )}
      </div>
    )
  }
}

const getCertificationsByCategory = (
  certifications,
  category
): List<RecordOf<Certification>> => {
  const certs =
    certifications && certifications.find(c => c.get(0) === category)
  return certs && certs.get(1)
}

export default injectIntl(SupplierAttributes)
