import React, { useState, useMemo } from 'react'
import CompanyProfileHeader from 'supplier/Company/components/CompanyProfileHeader'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from 'shared/components/DialogTitle'
import DialogActions from 'shared/components/DialogActions'
import Text from 'shared/components/Text'
import Button from 'shared/components/Button'
import StarsRating from 'shared/components/StarsRating'
import DropDownMenu from 'shared/components/DropDownMenu'
import MenuItem from 'shared/components/MenuItem'
import paths from '../../routes'
import parsePath from 'shared/utils/parsePath'
import SpendGauge from '../../../shared/components/SpendGauge'
import SpendTrend from '../../../shared/components/SpendTrend'
import Label from 'shared/components/Label'
import InviteToCommunitiesDialogContainer from '../../../shared/containers/InviteToCommunitiesDialogContainer'
import iconAddCart from 'shared/assets/icons/add-collection.svg'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import ConfirmationDialog from 'shared/components/ConfirmationDialog'
import { List, RecordOf, Map } from 'immutable'
import Certification from 'shared/models/Certification'
import Location from 'shared/models/Location'
import { WebsiteWarningType } from 'shared/components/WebsiteWarning'
import useSmallScreen from 'shared/utils/useSmallScreen'
import ShareProfileDialog from '../ShareProfileDialog'
import Tooltip from 'shared/components/Tooltip'
import { AccessControl } from 'shared/models/AccessControl'
import BecomeTealbotDialog from 'shared/containers/BecomeTealbotDialog/BecomeTealbotDialog'
import RootState from 'shared/models/RootState'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import { connect, useSelector } from 'react-redux'
import { getReasonKey } from '../../../../data-portal/store/actions'
import { useHistory } from 'react-router'
import Select from 'react-select'
import CustomSelect from 'shared/components/Select'
import { useDispatch } from 'react-redux'
import { notify } from 'shared/actions'
import AddOutreachContactForm from '../../../Insights/containers/AddOutreachContactForm'
import AddIcon from '@material-ui/icons/AddCircle'
import {
  cancelAddingOutreachContact,
  closeESGRequestDialog
} from '../../actions'
import User from 'shared/models/User'
import ReportFormDialog from 'shared/containers/ReportFormDialog'
import sorting from 'shared/utils/sortContactsByNameAndInput'
import ContactItem from 'shared/components/ContactItem'
import Input from 'shared/components/Input'
import useUserAccess from 'shared/utils/useUserAccess'
import Task from 'shared/models/Task'
import RequestDialog from './RequestDialog'
import newOutreachContactSelectors from 'buyer/SupplierProfile/selectors/newOutreachContactSelectors'
import analytics from 'shared/utils/analytics'
import Loading from 'shared/components/Loading'

const messages = defineMessages({
  update: {
    id: 'SupplierProfileHeader.PleaseUpdateYourSupplierProfile',
    defaultMessage: 'Please update your supplier profile.'
  },
  selfCertify: {
    id: 'SupplierProfileHeader.PleaseSelfCertify1',
    defaultMessage: `To comply with our diversity program requirements, you are being requested to log in to TealBook and (1) Upload your Diversity certification or (2) Self Certify as a Diverse supplier. For step-by-step instructions on the quick two-minute process for doing so, see the article below:`
  },
  esgSurvey: {
    id: 'SupplierProfileHeader.PleaseCompleteESGSurvey',
    defaultMessage: `{name} has invited you to complete TealBook’s sustainability survey to capture information about your company’s Environment, Social, Governance (ESG) efforts. This survey is an opportunity for you to share your organization’s sustainability efforts with potential buyers, and the results will be publicly available to buyers on the TealBook platform. By taking just a few minutes to complete this survey, you could attract more business from sustainability-minded companies. Click the button below to get started.`
  },
  selfCertLinkName: {
    id: 'SupplierProfileHeader.selfCertLinkName',
    defaultMessage: `Uploading Your Certification(s) / Diversity Self Certification`
  }
})

type Props = {
  userId: string
  supplierId: string
  connected: boolean
  showAddToVet: boolean
  overallRating: number
  redirectFrom?: string
  contacts: List<RecordOf<User>>
  invitedESGContacts: List<RecordOf<User>>
  clientName: string
  recentPercentile: number
  recentTrend: number
  isTealbot: boolean
  relationshipId: string
  isInCollection: boolean
  hasClientCommunities: boolean
  reviewOptions: RecordOf<{ rating: string }>
  name: string
  logoUrl: string
  description: string
  websiteUrl: string
  address?: RecordOf<Location> | string
  recentAmount: number
  attachments: List<
    RecordOf<{
      createdBy: string
      createdDate: Date
      documentFileUrl?: string
      expirationDate: Date
      type: string
    }>
  >
  certifications: List<[string, List<RecordOf<Certification>>]>
  coverUrl?: string
  phone?: string
  linkedInUrl?: string
  facebookUrl?: string
  twitterId?: string
  supplierType?: string
  alternativeNames?: List<string>
  status?: string
  domains?: Array<string>
  buyerDomains: List<string>
  cpiLocation?: RecordOf<{
    countryCpi: number
    country: string
  }>
  websiteWarning?: WebsiteWarningType
  attributes?: {
    isDoNotUse?: boolean
    isExisting?: boolean
    isNonRecruit?: boolean
    isPreferred?: boolean
    isPrivate?: boolean
    isRestricted?: boolean
    isSanctionedCompany?: boolean
    isSanctionedCountry?: boolean
    sanctionedCountryList?: List<string>
  }
  onConnect: () => void
  onUnfollow: () => void
  onAddVetSupplier: () => void
  onAddCollection: () => void
  onRemoveCollection: () => void
  onClickMarkAcquired: () => void
  onClickMarkInactive: () => void
  removeRelationship: (arg: string) => void
  onShare: (arg: { message: string; userToMessage: Array<string> }) => void
  onRequestProfileUpdate: (arg: {
    supplierId: string
    requestedContactId: string
  }) => void
  onRequestSelfCertify: (arg: {
    supplierId: string
    supplierName: string
    requestedContactId: string
  }) => void
  onRequestESGSurvey: (arg: {
    supplierId: string
    supplierName: string
    requestedContactId: string
    additionalRequestContactIds: Array<string>
  }) => void
  onRequestNewContactForESG: (arg: {
    taskId: string | undefined
    userId: string
    supplierId: string
    requestedContactId: string
    additionalRequestContactIds: Array<string>
  }) => void
  onMessageUser: (arg: string) => void
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
  validations?: List<
    RecordOf<{
      confirmed: boolean
      subCategory: string
      timeStamp: string
    }>
  >
  accessControl: AccessControl
  hasAccessToDataPortal: boolean
  onGetReasonKey: (payload: {
    orgUnitId: string
    orgName: string
    userMobilePhone: string
  }) => void
  onClickNewUser: () => void
  onClickCancel: () => void
  userMobilePhone: string
  surveySubmitted: boolean
  openESGSurveyDialog: boolean
  inviteAnotherESGContactDialog: boolean
  createdESGRequest?: RecordOf<Task>
  onTransferSpend: (payload: {
    transferFromRelationshipId: string
    internalSupplierId: string
    transferToOrgUnitId: string
  }) => void
  internalSupplierIds: List<string>
  selectedUserId: string
  selectedUserName: string
  isTransferringSpend: boolean
}

export const SupplierProfileHeader = (props: Props) => {
  const {
    isTealbot,
    userId,
    relationshipId,
    removeRelationship,
    isInCollection,
    hasClientCommunities,
    supplierId,
    connected,
    onConnect,
    onUnfollow,
    showAddToVet,
    onAddVetSupplier,
    onAddCollection,
    onRemoveCollection,
    overallRating,
    reviewOptions,
    name,
    logoUrl,
    description,
    websiteUrl,
    recentPercentile,
    recentTrend,
    recentAmount,
    attachments,
    certifications,
    redirectFrom,
    contacts,
    invitedESGContacts,
    onClickMarkInactive,
    onClickMarkAcquired,
    onRequestProfileUpdate,
    onRequestSelfCertify,
    onRequestESGSurvey,
    onRequestNewContactForESG,
    onMessageUser,
    onClickNewUser,
    onClickCancel,
    buyerDomains,
    attachmentTypes,
    relationshipTypes,
    accessControl,
    hasAccessToDataPortal,
    onGetReasonKey,
    userMobilePhone,
    onShare,
    clientName,
    surveySubmitted,
    openESGSurveyDialog,
    inviteAnotherESGContactDialog,
    createdESGRequest,
    onTransferSpend,
    internalSupplierIds,
    selectedUserId,
    selectedUserName,
    isTransferringSpend,
    ...rest
  } = props
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const matchMobile = useSmallScreen()
  const userAccess = useUserAccess()
  const orgUnitId = useSelector(sessionSelectors.getOrgUnitId)
  const orgUnitName: string = useSelector(
    orgsSelectors.getCurrentUserOrgUnitName
  )

  const [sharingSupplier, setSharingSupplier] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [openInviteDialog, setOpenInviteDialog] = useState<boolean>(false)
  const [openReportDialog, setOpenReportDialog] = useState<boolean>(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(
    false
  )
  const [openSelfCertifyDialog, setOpenSelfCertifyDialog] = useState<boolean>(
    false
  )
  const [isESGSurveyRequest, setisESGSurveyRequest] = useState<boolean>(false)
  const [isSelfCertRequest, setIsSelfCertRequest] = useState<boolean>(false)
  const [isProfileUpdateRequest, setIsProfileUpdateRequest] = useState<boolean>(
    false
  )
  const [selectInput, setSelectInput] = useState<string>('')
  const [selectedContactId, setSelectedContactId] = useState<string>('')
  const [selectedEsgContacts, setSelectedEsgContacts] = useState<Array<any>>([])
  const [openTransferSpendDialog, setOpenTransferSpendDialog] = useState<
    boolean
  >(false)
  const [internalSupplierId, setInternalSupplierId] = useState<string>('')
  const [transferToOrgUnitId, setTransferToOrgUnitId] = useState<string>('')
  const [inviteAnotherContact, setInviteAnotherContact] = useState<boolean>(
    false
  )

  const handleSelectInputChange = (inputValue: string) => {
    setSelectInput(inputValue)
  }
  const sortedContacts: List<RecordOf<User>> | List<undefined> = useMemo(
    () => sorting.sortContactsByNameAndInput(contacts, selectInput),
    [contacts, selectInput]
  )
  const sortedContactsBySustainability:
    | List<RecordOf<User>>
    | List<undefined> = useMemo(
    () =>
      sorting.sortContactsBySustainabilityContact(
        invitedESGContacts,
        selectInput
      ),
    [invitedESGContacts, selectInput]
  )

  const filterOption = props => {
    const user = props.data
    const userFullName = `${user.firstName &&
      user.firstName.trim()} ${user.lastName &&
      user.lastName.trim()}`.toLowerCase()

    return userFullName.includes(selectInput.toLowerCase())
  }

  const formatOptionLabel = (user, data) => {
    return (
      user && (
        <ContactItem
          key={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
          profilePictureUrl={user.profilePictureUrl}
          title={user.title}
          isSustainabilityContact={user.isSustainabilityContact}
          isEsgSurvey={openESGSurveyDialog}
        />
      )
    )
  }

  const handleOpenRequestProfileUpdate = () => {
    setDialogOpen(true)
    setIsProfileUpdateRequest(true)
    setSelectedContactId('')
  }

  const handleRequestProfileUpdate = e => {
    e.preventDefault()
    onRequestProfileUpdate({
      supplierId,
      requestedContactId: selectedContactId
    })
    dispatch(notify({ message: 'Request successfully sent' }))
    setDialogOpen(false)
  }

  const handleRequestClose = () => {
    setDialogOpen(false)
  }

  const handleCloseESGSurveyDialog = () => {
    dispatch(closeESGRequestDialog())
  }

  const handleCancelESGSurveyRequest = e => {
    handleCloseESGSurveyDialog()
  }

  const handleCancelESGNewUserForm = () => {
    onClickCancel()
    handleCloseESGSurveyDialog()
  }

  const handleCancelSelfCertifyNewUserForm = () => {
    onClickCancel()
    handleToggleSelfCertifyDialog()
  }

  const handleCancelProfileUpdateNewUserForm = () => {
    onClickCancel()
    setDialogOpen(true)
  }

  const handleESGSurveyRequest = e => {
    e.preventDefault()
    let additionalContactIds = selectedEsgContacts.slice(1)
    if (inviteAnotherESGContactDialog) {
      let taskId = createdESGRequest?.get('id')
      onRequestNewContactForESG({
        taskId: taskId,
        userId: userId,
        supplierId: supplierId,
        requestedContactId: selectedEsgContacts[0].id,
        additionalRequestContactIds: additionalContactIds.map(
          contact => contact.id
        )
      })
    } else {
      onRequestESGSurvey({
        supplierId: supplierId,
        supplierName: name,
        requestedContactId: selectedEsgContacts[0].id,
        additionalRequestContactIds: additionalContactIds.map(
          contact => contact.id
        )
      })
    }
    dispatch(notify({ message: 'Message successfully sent' }))

    handleCloseESGSurveyDialog()
  }
  const handleOnClickAddUserButtonInESGSurveyDialog = () => {
    setisESGSurveyRequest(true)
    setIsSelfCertRequest(false)
    if (inviteAnotherESGContactDialog) {
      setInviteAnotherContact(true)
    }
    onClickNewUser()
    handleCloseESGSurveyDialog()
  }
  const handleOnClickAddUserProfileUpdate = () => {
    setIsSelfCertRequest(false)
    setisESGSurveyRequest(false)
    setInviteAnotherContact(false)
    onClickNewUser()
    handleRequestClose()
  }
  const handleToggleSelfCertifyDialog = () => {
    setOpenSelfCertifyDialog(!openSelfCertifyDialog)
  }

  const handleCancelSelfCertifyRequest = e => {
    handleToggleSelfCertifyDialog()
  }
  const handleContactSelected = (data, metaAction) => {
    setSelectedContactId(data.id)
    setSelectInput('')
  }
  const handleESGContactsSelected = (data, metaAction) => {
    setSelectedEsgContacts(data)
    setSelectInput('')
  }

  const handleSelfCertifyRequest = e => {
    e.preventDefault()
    onRequestSelfCertify({
      supplierId: supplierId,
      supplierName: name,
      requestedContactId: selectedContactId
    })
    dispatch(notify({ message: 'Request successfully sent' }))
    dispatch(cancelAddingOutreachContact())

    handleToggleSelfCertifyDialog()
  }
  const getMyCompanyPath = scrollTo => {
    const path = redirectFrom
      ? `${parsePath(paths.myCompany, {
          supplierId
        })}?${scrollTo}=true&redirectFrom=${redirectFrom}`
      : `${parsePath(paths.myCompany, { supplierId })}?${scrollTo}=true`

    return path
  }
  const handleOnClickAddUserSelfCertify = () => {
    setIsSelfCertRequest(true)
    setisESGSurveyRequest(false)
    setInviteAnotherContact(false)
    onClickNewUser()
    handleToggleSelfCertifyDialog()
  }
  const toggleReportDialog = () => {
    setOpenReportDialog(!openReportDialog)
  }

  const toggleInviteDialog = () => {
    setOpenInviteDialog(!openInviteDialog)
  }

  const toggleConfirmationDialog = () => {
    setOpenConfirmationDialog(!openConfirmationDialog)
  }

  const toggleSharingSupplier = () => {
    setSharingSupplier(!sharingSupplier)
  }

  const handleShareSupplier = ({ userToMessage, message }) => {
    setSharingSupplier(false)

    onShare({
      message,
      userToMessage
    })
  }

  const handleToggleTransferSpendDialog = () => {
    setOpenTransferSpendDialog(!openTransferSpendDialog)
    setInternalSupplierId('')
    setTransferToOrgUnitId('')
  }
  const handleTransferSpend = e => {
    e.preventDefault()
    onTransferSpend({
      transferFromRelationshipId: relationshipId,
      internalSupplierId,
      transferToOrgUnitId
    })
    handleToggleTransferSpendDialog()
  }

  const inValidOrgUnitId = () => {
    if (transferToOrgUnitId) {
      let transferToOrgUnitIdArray = transferToOrgUnitId.split('-')
      if (
        transferToOrgUnitIdArray[0] !== 'OrgUnit' ||
        transferToOrgUnitIdArray.length < 3
      ) {
        return true
      } else return false
    }
    return false
  }

  const onFollowClick = () => {
    onConnect()
    analytics.track('Supplier Followed', {
      eventSource: 'Supplier Profile',
      action: 'Followed',
      supplierName: name,
      supplierId: supplierId,
      orgUnitId,
      orgUnitName
    })
  }

  return (
    <CompanyProfileHeader
      publicView
      name={name}
      logoUrl={logoUrl}
      description={description}
      websiteUrl={websiteUrl}
      attachments={attachments && attachments.toJS()}
      certifications={certifications}
      attachmentTypes={attachmentTypes}
      relationshipTypes={relationshipTypes}
      surveySubmitted={
        userAccess.accessSustainabilitySurveyReport && surveySubmitted
      }
      {...rest}
    >
      <RequestDialog
        InputValue={selectInput}
        onInputChange={handleSelectInputChange}
        onChange={handleContactSelected}
        contacts={sortedContacts}
        formatOptionLabel={formatOptionLabel}
        filterOption={filterOption}
        handleOnClickAddUser={handleOnClickAddUserProfileUpdate}
        onClose={handleRequestClose}
        open={dialogOpen}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
        onSubmit={handleRequestProfileUpdate}
        onCancel={handleRequestClose}
        description={
          <FormattedMessage
            id='SupplierProfileHeader.RequestProfileUpdate'
            defaultMessage='Request Profile Update'
          />
        }
      />

      <RequestDialog
        InputValue={selectInput}
        onInputChange={handleSelectInputChange}
        onChange={handleContactSelected}
        contacts={sortedContacts}
        formatOptionLabel={formatOptionLabel}
        filterOption={filterOption}
        handleOnClickAddUser={handleOnClickAddUserSelfCertify}
        onClose={handleCancelSelfCertifyRequest}
        open={openSelfCertifyDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
        onSubmit={handleSelfCertifyRequest}
        onCancel={handleCancelSelfCertifyRequest}
        description={
          <FormattedMessage
            id='SupplierProfileHeader.Self Certification Request'
            defaultMessage='Self Certification Request'
          />
        }
      />
      <AddOutreachContactForm
        defaultMessage={
          isESGSurveyRequest
            ? intl.formatMessage(messages.esgSurvey, {
                name: clientName
              })
            : intl.formatMessage(messages.selfCertify, {
                name: clientName
              })
        }
        requestSelfCertify={isSelfCertRequest ? true : false}
        requestESGSurvey={isESGSurveyRequest ? true : false}
        shareRequestSelfCertifyLink={isSelfCertRequest ? true : false}
        inviteAnotherContact={inviteAnotherContact ? true : false}
        supplierId={supplierId}
        supplierName={name}
        taskId={createdESGRequest?.get('id')}
        onClickCancel={
          isESGSurveyRequest
            ? handleCancelESGNewUserForm
            : isSelfCertRequest
            ? handleCancelSelfCertifyNewUserForm
            : handleCancelProfileUpdateNewUserForm
        }
        skipMessageDialog={isProfileUpdateRequest || isSelfCertRequest}
        requestProfileUpdate={isProfileUpdateRequest}
      />
      <Dialog
        onClose={handleCloseESGSurveyDialog}
        open={openESGSurveyDialog || inviteAnotherESGContactDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleESGSurveyRequest}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.ESGSurvey'
              defaultMessage='Sustainability Survey Request'
            />
          </DialogTitle>
          <DialogContent className='mt3'>
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.ContactSelectInstruction'
                defaultMessage='Indicate a Contact you would like to complete this request'
              />
            </Text>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.ContactSelect'
                defaultMessage='Contacts'
              />
            </Label>
            <Select
              aria-label='Select Contact'
              isMulti
              InputValue={selectInput}
              onInputChange={handleSelectInputChange}
              onChange={handleESGContactsSelected}
              placeholder={
                (
                  <FormattedMessage
                    id='UserLookup.Select Contact'
                    defaultMessage='Type in Contact Name'
                  />
                ) as any
              }
              options={sortedContactsBySustainability.toJS()}
              formatOptionLabel={formatOptionLabel}
              filterOption={filterOption}
              getOptionValue={opt => opt.id || opt.value}
              noOptionsMessage={inputValue => 'No Contacts Found'}
              controlShouldRenderValue={true}
              closeMenuOnSelect={false}
              styles={{
                control: base => ({
                  ...base,
                  height: 'auto'
                }),
                input: base => ({
                  ...base,
                  fontSize: '.875rem',
                  height: 'auto'
                }),
                placeholder: base => ({
                  ...base,
                  fontSize: '.875rem'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                multiValueLabel: base => ({ ...base, height: 33 }),
                multiValueRemove: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  },
                  marginTop: '7px'
                }),
                noOptionsMessage: base => ({ ...base, fontSize: '.875rem' }),
                option: (base, { isDisabled, isFocused }) => ({
                  ...base,
                  backgroundColor: isDisabled
                    ? 'inherit'
                    : isFocused
                    ? '#E0E0E0'
                    : 'inherit',
                  ':hover': {
                    ...base[':hover'],
                    backgroundColor: !isDisabled && '#e0e0e0'
                  }
                })
              }}
              menuPortalTarget={document.body}
            />
            <div className='mb3 mt3'>
              <div className='dib w-35'>
                <Button
                  color='primary'
                  variant='text'
                  startIcon={<AddIcon />}
                  label={
                    <FormattedMessage
                      id='SupplierProfileHeader.AddContact'
                      defaultMessage='Add a New Contact'
                    />
                  }
                  onClick={() => handleOnClickAddUserButtonInESGSurveyDialog()}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.SendRequest'
                  defaultMessage='Send Request'
                />
              }
              disabled={!selectedEsgContacts || !selectedEsgContacts.length}
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleCancelESGSurveyRequest}
              secondary
              className='mr3'
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>

      <ReportFormDialog
        onToggleReportDialog={toggleReportDialog}
        openReportDialog={openReportDialog}
        taskType='reportSupplier'
      />
      <Dialog
        onClose={handleToggleTransferSpendDialog}
        open={openTransferSpendDialog}
        fullWidth
        disableBackdropClick
        fullScreen={matchMobile}
      >
        <form onSubmit={handleTransferSpend}>
          <DialogTitle>
            <FormattedMessage
              id='SupplierProfileHeader.TransferSpendToDifferentSupplier'
              defaultMessage='Transfer spend to another Supplier'
            />
          </DialogTitle>
          <DialogContent>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.Steps'
                defaultMessage='Steps for transferring spend to another supplier'
              />
            </Label>
            <ol className='lh-copy f7 fw4 mid-gray'>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendOne'
                  defaultMessage='Log in as Concierge for the client in question'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendTwo'
                  defaultMessage='Navigate to supplier profile you want to transfer spend FROM'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendThree'
                  defaultMessage="Input the OrgUnit id for the supplier you're transferring spend TO"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendFour'
                  defaultMessage="Input the internal supplier id that's being transferred"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendFive'
                  defaultMessage='Click "Transfer Spend"'
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendSix'
                  defaultMessage="Check My Company and EcoVadis tab, if there isn't any other activity besides the spend you're transferring, you can 'Delete Relationship'"
                />
              </li>
              <li>
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpendsSeven'
                  defaultMessage='Navigate to Admin and click "Compute Trends"'
                />
              </li>
            </ol>
            <Label>
              <FormattedMessage
                id='SupplierProfileHeader.transferToOrgUnitId'
                defaultMessage='Transfer spend to OrgUnit Id'
              />
            </Label>
            <Input
              name='transferToOrgUnitId'
              required
              value={transferToOrgUnitId}
              onChange={e => setTransferToOrgUnitId(e.currentTarget.value)}
              placeholder={'OrgUnit-5157071787917312-5629499534213120'}
              type='text'
              meta={{
                error: inValidOrgUnitId() ? 'Invalid entry' : '',
                touched: true
              }}
            />
            <Label htmlFor='selectinternalsupplier'>
              <FormattedMessage
                id='SupplierProfileHeader.internalSupplierId'
                defaultMessage='Internal supplier Id'
              />
            </Label>
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.SelectInternalSupplierIdNote'
                defaultMessage='Note: these are the internal supplier ids currently attached to this supplier by this buyer'
              />
            </Text>
            <CustomSelect
              name='selectinternalsupplier'
              onChange={e => setInternalSupplierId(e.currentTarget.value)}
              value={internalSupplierId}
              required
            >
              <FormattedMessage
                id='SupplierProfileHeader.SelectInternalSupplierId'
                defaultMessage='Select internal supplier Id'
              >
                {label => <option value=''>{label}</option>}
              </FormattedMessage>
              {internalSupplierIds.map(id => (
                <option key={id} value={id}>
                  {id}
                </option>
              ))}
            </CustomSelect>
          </DialogContent>
          <DialogActions className='fixed static-ns bottom-1'>
            <Button
              size={matchMobile ? 'large' : undefined}
              disabled={isTransferringSpend}
              label={
                <FormattedMessage
                  id='SupplierProfileHeader.TransferSpend'
                  defaultMessage='Transfer Spend'
                />
              }
              type='submit'
            />
            <Button
              size={matchMobile ? 'large' : undefined}
              label={<FormattedMessage id='CancelButton' />}
              onClick={handleToggleTransferSpendDialog}
              secondary
              autoSize
            />
          </DialogActions>
        </form>
      </Dialog>
      {accessControl.access === 'full' && (
        <>
          <ShareProfileDialog
            matchMobile={matchMobile}
            onClose={toggleSharingSupplier}
            open={sharingSupplier}
            fullScreen={matchMobile}
            supplierName={name}
            logoUrl={logoUrl}
            description={description}
            handleShareSupplier={handleShareSupplier}
            buyerDomains={buyerDomains}
          />

          <InviteToCommunitiesDialogContainer
            supplierId={supplierId}
            open={openInviteDialog}
            onCloseInviteDialog={toggleInviteDialog}
          />

          <ConfirmationDialog
            open={openConfirmationDialog}
            onClose={toggleConfirmationDialog}
            onConfirm={() => removeRelationship(relationshipId)}
          >
            <Text>
              <FormattedMessage
                id='SupplierProfileHeader.ConfirmDeleteRelationship'
                defaultMessage='Are you sure you want to remove the relationship?'
              />
            </Text>
          </ConfirmationDialog>
        </>
      )}

      <div className='tr'>
        {accessControl.access === 'full' && (
          <>
            <SpendGauge
              recentPercentile={recentPercentile}
              recentAmount={recentAmount}
              definition
              linkTo={getMyCompanyPath('scrollToAttribute')}
            />
            <SpendTrend
              recentTrend={recentTrend}
              linkTo={getMyCompanyPath('scrollToAttribute')}
            />
            {reviewOptions && reviewOptions.get('rating') && !!overallRating && (
              <div className='dib mr4'>
                <StarsRating
                  value={overallRating}
                  mini
                  onClick={() =>
                    history.push(getMyCompanyPath('scrollToReviews'))
                  }
                />
              </div>
            )}

            {!connected ? (
              !isTealbot ? (
                <h5
                  className='f7 fw4 dim mid-gray bg-light-gray br2 ba pointer dib b--none pv2 ph2-5 ma0 mr2'
                  onClick={onFollowClick}
                >
                  <FormattedMessage
                    id='SupplierProfileHeader.Follow'
                    defaultMessage='Follow'
                  />
                </h5>
              ) : null
            ) : (
              <h5 className='f7 fw4 teal br2 ba dib b--none ma0 mr2'>
                <FormattedMessage
                  id='SupplierProfileHeader.Following'
                  defaultMessage='Following'
                />
              </h5>
            )}
          </>
        )}
        {!isInCollection ? (
          accessControl.access === 'punchout' ? (
            <Button size='small' autoSize onClick={onAddCollection}>
              <FormattedMessage
                id='SupplierProfileHeader.AddToList'
                defaultMessage='Add to List'
              />
            </Button>
          ) : (
            <Tooltip
              title={
                <FormattedMessage
                  id='SupplierProfileHeader.AddToCollection'
                  defaultMessage={
                    'These are your currently selected suppliers. TealBook keeps track of them between sessions. They also show up to the right side of the Supplier list.'
                  }
                />
              }
            >
              <img
                src={iconAddCart}
                alt='add to collection'
                className='w1-5 v-mid pointer dim ml3 mr2'
                onClick={onAddCollection}
              />
            </Tooltip>
          )
        ) : accessControl.access === 'punchout' ? (
          <Button
            size='small'
            variant='text'
            autoSize
            caution
            onClick={onRemoveCollection}
          >
            <FormattedMessage
              id='SupplierProfileHeader.RemoveFromList'
              defaultMessage='Remove from List'
            />
          </Button>
        ) : null}

        {showAddToVet && (
          <h5
            className='f8 fw6 ma0 dib v-mid gray pointer underline-hover hover-teal dim'
            onClick={onAddVetSupplier}
          >
            <span
              className='dib br--left br2 white pl1 pv1 pr2 v-mid f9 fw4'
              style={{ background: '#8B8B8B' }}
            >
              RFI
            </span>
            <span
              className='dib bg-white ba br-100 v-mid relative tc f4 nl2'
              style={{
                color: '#8B8B8B',
                height: '15px',
                width: '15px',
                lineHeight: '15px',
                borderColor: '#8B8B8B',
                borderWidth: '2px'
              }}
            >
              +
            </span>
          </h5>
        )}
        {isTransferringSpend ? (
          <Loading/>
        ) : (
          <DropDownMenu>
            {accessControl.access === 'full' && hasClientCommunities && (
              <MenuItem onClick={toggleInviteDialog}>
                <FormattedMessage
                  id='SupplierProfileHeader.InviteToCommunity'
                  defaultMessage='Invite to community'
                />
              </MenuItem>
            )}

            {accessControl.access === 'full' && (
              <MenuItem onClick={toggleSharingSupplier}>
                <FormattedMessage
                  id='SupplierProfileHeader.Share'
                  defaultMessage='Share'
                />
              </MenuItem>
            )}

            <MenuItem onClick={handleOpenRequestProfileUpdate}>
              <FormattedMessage
                id='SupplierProfileHeader.RequestProfileUpdate'
                defaultMessage='Request Profile Update'
              />
            </MenuItem>

            <MenuItem onClick={handleToggleSelfCertifyDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.RequestSelfCertification'
                defaultMessage='Request Self Certification'
              />
            </MenuItem>

            <MenuItem onClick={toggleReportDialog}>
              <FormattedMessage
                id='SupplierProfileHeader.Report'
                defaultMessage='Report'
              />
            </MenuItem>

            {accessControl.access === 'full' && connected && (
              <MenuItem onClick={onUnfollow}>
                <FormattedMessage
                  id='SupplierProfileHeader.Unfollow'
                  defaultMessage='Unfollow'
                />
              </MenuItem>
            )}
            {isTealbot && (
              <MenuItem onClick={onClickMarkAcquired}>
                <FormattedMessage
                  id='SupplierProfileHeader.markAsAcquired'
                  defaultMessage='Mark as Acquired'
                />
              </MenuItem>
            )}

            {isTealbot && (
              <MenuItem onClick={onClickMarkInactive}>
                <FormattedMessage
                  id='SupplierProfileHeader.markAsInactive'
                  defaultMessage='Mark as Inactive'
                />
              </MenuItem>
            )}
            {isTealbot && relationshipId && (
              <MenuItem onClick={toggleConfirmationDialog}>
                <FormattedMessage
                  id='SupplierProfileHeader.DeleteRelationship'
                  defaultMessage='Delete Relationship'
                />
              </MenuItem>
            )}
            {isTealbot && relationshipId && !!internalSupplierIds.size && (
              <MenuItem
                onClick={handleToggleTransferSpendDialog}
                disabled={isTransferringSpend}
              >
                {isTransferringSpend ? (
                  <FormattedMessage
                    id='SupplierProfileHeader.Transfering'
                    defaultMessage='Transfering....'
                  />
                ) : (
                  <FormattedMessage
                    id='SupplierProfileHeader.TransferSpend'
                    defaultMessage='Transfer Spend'
                  />
                )}
              </MenuItem>
            )}

            {hasAccessToDataPortal && (
              <MenuItem
                onClick={() => {
                  //POST for oneTime pin code
                  //saving orgUnitId and orgName to redux state so can pre-populate dialog once SMS successfully sent
                  onGetReasonKey({
                    orgUnitId: supplierId,
                    orgName: name,
                    userMobilePhone: userMobilePhone
                  })
                }}
              >
                <FormattedMessage
                  id='SupplierProfileHeader.LoginAsConcierge'
                  defaultMessage='Login as Concierge'
                />
              </MenuItem>
            )}
          </DropDownMenu>
        )}
      </div>
      <BecomeTealbotDialog />
    </CompanyProfileHeader>
  )
}

export default connect(
  (state: RootState) => {
    const user = sessionSelectors.getUser(state)
    const selectedUser = newOutreachContactSelectors.getSelectedUser(state)
    return {
      userMobilePhone: user && user.get('mobilePhone'),
      selectedUserId: selectedUser && selectedUser.get('id'),
      selectedUserName:
        selectedUser &&
        `${selectedUser.get('firstName')} ${selectedUser.get('lastName')}`
    }
  },
  {
    onGetReasonKey: getReasonKey
  }
)(SupplierProfileHeader)
