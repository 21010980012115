import { createSelector } from 'reselect'
import { fromJS, List } from 'immutable'
import profileSelectors from '../../../SupplierProfile/selectors/profileSelectors'

export const getById = (state, relationshipId) => {
  return state.getIn(['buyer', 'relationships', 'byId', relationshipId])
}

export const getBySupplier = (state, supplierId) => {
  const relationshipId = state.getIn([
    'buyer',
    'relationships',
    'bySupplierId',
    supplierId
  ])
  return relationshipId && getById(state, relationshipId)
}

export const getAttributes = (state, supplierId) => {
  const relationship = getBySupplier(state, supplierId)
  return relationship && relationship.get('attributes')
}

export const getCustomAttributes = (state, supplierId) => {
  const relationship = getBySupplier(state, supplierId)
  return relationship && relationship.get('customAttributes')
}

export const getStandardAttributes = createSelector(
  getBySupplier,
  relationship => {
    const internalSupplierId =
      relationship && relationship.get('internalSupplierId')
    const internalCategory =
      relationship && relationship.get('internalCategory')
    const spend = relationship && relationship.get('spend')

    return fromJS({
      internalSupplierId,
      internalCategory,
      spend
    })
  }
)

export const getAttachments = (state, supplierId) => {
  const relationship = getBySupplier(state, supplierId)
  return relationship && relationship.get('attachments')
}

const getBannedAttributes = createSelector(
  state => profileSelectors.getDataField(state, 'flagCountry'),
  state => profileSelectors.getDataField(state, 'bannedCompanyIds'),
  (bannedCountry, bannedCompanyIds) => ({
    country: bannedCountry,
    company: bannedCompanyIds && bannedCompanyIds.size > 0
  })
)

export const getSupplierAttributes = createSelector(
  getAttributes,
  getAttachments,
  getBannedAttributes,
  (attributes, attachments, banned) => {
    return {
      isPreferred: attributes && attributes.getIn(['preferred', 'status']),
      isDoNotUse: attributes && attributes.getIn(['doNotUse', 'status']),
      isRestricted: attributes && attributes.getIn(['restricted', 'status']),
      isExisting: attributes && attributes.getIn(['existing', 'status']),
      isNonRecruit: attributes && attributes.getIn(['nonRecruit', 'status']),
      isPrivate: attributes && attributes.getIn(['privateToClients', 'status']),
      isSanctionedCountry:
        banned.country && banned.country.toJS().some(country => country.status),
      sanctionedCountryList:
        banned.country &&
        banned.country
          .filter(country => country.get('status'))
          .map(country => country.get('country')),
      isSanctionedCompany: banned.company
    }
  }
)

export const getSpendBySupplierId = (state, supplierId) => {
  const relationship = getBySupplier(state, supplierId)

  return relationship && relationship.get('spend')
}
export const getInternalSupplierId = createSelector(
  getBySupplier,
  relationship => {
    if (relationship) {
      // internal supplier id from the Relationship table
      const relationshipInternalSupplierId =
        relationship.get('internalSupplierId') || List([])
      // internal supplier ids from the RelationshipSpendLineItems table
      // grabbing from both spots in case tables are out of sync
      const spendDetails = relationship.get('spend')?.get('details') || List([])
      if (spendDetails.size > 0) {
        return spendDetails.reduce((result, currentDetails) => {
          const internalSupplierId = currentDetails.get('internalSupplierId')
          if (internalSupplierId && !result.includes(internalSupplierId)) {
            result = result.push(internalSupplierId)
          }
          return result
        }, relationshipInternalSupplierId)
      } else {
        return relationshipInternalSupplierId
      }
    } else {
      return List([])
    }
  }
)
