import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import SupplierProfileHeader from '../../components/SupplierProfileHeader'
import { connectToSupplier, unfollowSupplier } from '../../../shared/actions'
import { messageUser } from 'shared/actions'
import { addVetSupplier } from '../../../Vets/actions'
import {
  requestForProfileUpdate,
  requestforSelfCertify,
  requestForESGSurvey,
  requestNewContactForESGSurvey,
  updateSupplierStatus,
  removeRelationship,
  openAddOutreachContactForm,
  cancelAddingOutreachContact,
  transferSpend
} from '../../actions'
import cardsSelectors from '../../../shared/selectors/cardsSelectors'
import relationshipsSelector from '../../../shared/selectors/relationshipsSelectors'
import profileSelectors from '../../selectors/profileSelectors'
import currentVetSuppliersSelector from '../../../Vets/selectors/currentVetSuppliersSelectors'
import collectionSelectors from '../../../Search/selectors/collectionSelectors'
import settingsSelectors from '../../../shared/selectors/settingsSelectors'
import sessionSelectors from 'shared/selectors/sessionSelectors'
import communitiesSelectors from '../../../Communities/selectors/communitiesSelectors'
import supplierRelationshipsSelectors from '../../../shared/selectors/supplierRelationshipsSelectors'
import React, { Component } from 'react'
import { fromJS, RecordOf, List, Map } from 'immutable'
import { addCollection, removeCollection } from '../../../Search/actions'
import Certification from 'shared/models/Certification'
import Location from 'shared/models/Location'
import RootState from 'shared/models/RootState'
import { createSelector } from 'reselect'
import { AccessControl } from 'shared/models/AccessControl'
import orgsSelectors from 'shared/selectors/orgsSelectors'
import surveySelectors from 'buyer/SupplierProfile/selectors/surveySelectors'
import User from 'shared/models/User'
import Task from 'shared/models/Task'

type Props = {
  redirectFrom?: string
  userId: string
  supplierId: string
  buyerOrgUnitId: string
  name: string
  logoUrl: string
  relatedCompany: boolean
  connected: boolean
  description: string
  websiteUrl: string
  certifications: List<[string, List<RecordOf<Certification>>]>
  recentPercentile: number
  recentTrend: number
  recentAmount: number
  isTealbot: boolean
  relationshipId: string
  showAddToVet: boolean
  attachments: List<
    RecordOf<{
      createdBy: string
      createdDate: Date
      documentFileUrl?: string
      expirationDate: Date
      type: string
    }>
  >
  address?: RecordOf<Location> | string
  overallRating: number
  reviewOptions: RecordOf<{ rating: string }>
  hasClientCommunities: boolean
  isInCollection: boolean
  match: {
    params: { supplierId: string }
    isExact: boolean
    path: string
    url: string
  }
  accessControl: AccessControl
  buyerDomains: List<string>
  addCollection: (
    arg1: { supplierId: string; userId: string },
    arg2: { id: string; name: string; logoUrl: string; relatedCompany: boolean }
  ) => void
  removeCollection: (payload: { userId: string; supplierId: string }) => void
  connectToSupplier: (arg: { supplierId: string }) => void
  unfollowSupplier: (arg: string) => void
  addVetSupplier: (arg: { vetId: string; supplierIds: Array<string> }) => void
  messageUser: (arg: string) => void
  updateSupplierStatus: (arg: { supplierId: string; status: string }) => void
  onRequestProfileUpdate: () => void
  onRequestSelfCertify: () => void
  onRequestESGSurvey: () => void
  onRequestNewContactForESG: () => void
  removeRelationship: () => void
  onClickNewUser: () => void
  onClickCancel: () => void
  attachmentTypes: Map<string, Map<string, string>>
  relationshipTypes: Map<string, Map<string, string>>
  validations?: any
  hasAccessToDataPortal: boolean
  contacts: List<RecordOf<User>>
  invitedESGContacts: List<RecordOf<User>>
  clientName: string
  surveySubmitted: boolean
  openESGSurveyDialog: boolean
  inviteAnotherESGContactDialog: boolean
  createdESGRequest?: RecordOf<Task>
  transferSpend: (payload: {
    transferFromRelationshipId: string
    internalSupplierId: string
    transferToOrgUnitId: string
  }) => void
  internalSupplierIds: List<string>
  isTransferringSpend: boolean
} & RouteComponentProps

export class SupplierProfileHeaderContainer extends Component<Props> {
  handleAddCollection = () => {
    const {
      addCollection,
      userId,
      supplierId,
      name,
      logoUrl,
      relatedCompany
    } = this.props
    addCollection(
      { supplierId, userId },
      { id: supplierId, name, logoUrl, relatedCompany }
    )
  }

  handleRemoveCollection = () => {
    const { removeCollection, userId, supplierId } = this.props
    removeCollection({ supplierId, userId })
  }

  handleConnect = () => {
    const { connectToSupplier } = this.props
    connectToSupplier({ supplierId: this.props.match.params.supplierId })
  }

  handleUnfollow = () => {
    const { unfollowSupplier } = this.props
    unfollowSupplier(this.props.match.params.supplierId)
  }

  handleAddVetSupplier = () => {
    const { addVetSupplier, redirectFrom, match } = this.props
    const vetId =
      redirectFrom && redirectFrom.includes('vets')
        ? redirectFrom.replace(/.*vets\/(.*)\/.*/, '$1')
        : undefined

    if (!vetId) {
      return false
    }
    const supplierId = match.params.supplierId
    addVetSupplier({ vetId, supplierIds: [supplierId] })
  }

  handleShareSupplier = ({ userToMessage, message = '' }) => {
    const { messageUser, supplierId, buyerOrgUnitId } = this.props

    userToMessage.forEach(userToMessageId => {
      messageUser(
        fromJS({
          message,
          supplierToShare: supplierId,
          userToMessageId,
          orgUnitId: buyerOrgUnitId
        })
      )
    })
  }

  handleMarkSupplierAsInactive = () => {
    const { updateSupplierStatus, supplierId } = this.props
    updateSupplierStatus({
      supplierId,
      status: 'Inactive'
    })
  }

  handleMarkSupplierAsAcquired = () => {
    const { updateSupplierStatus, supplierId } = this.props
    updateSupplierStatus({
      supplierId,
      status: 'Acquired'
    })
  }

  render() {
    const {
      connectToSupplier,
      unfollowSupplier,
      addVetSupplier,
      messageUser,
      addCollection,
      updateSupplierStatus,
      attachmentTypes,
      relationshipTypes,
      validations,
      hasAccessToDataPortal,
      transferSpend,
      ...rest
    } = this.props

    return (
      <SupplierProfileHeader
        onShare={this.handleShareSupplier}
        onConnect={this.handleConnect}
        onUnfollow={this.handleUnfollow}
        onAddVetSupplier={this.handleAddVetSupplier}
        onAddCollection={this.handleAddCollection}
        onRemoveCollection={this.handleRemoveCollection}
        onClickMarkAcquired={this.handleMarkSupplierAsAcquired}
        onClickMarkInactive={this.handleMarkSupplierAsInactive}
        attachmentTypes={attachmentTypes}
        relationshipTypes={relationshipTypes}
        validations={validations && validations.valueSeq()}
        hasAccessToDataPortal={hasAccessToDataPortal}
        onMessageUser={messageUser}
        onTransferSpend={transferSpend}
        {...rest}
      />
    )
  }
}

const getNonPotentialCerts = createSelector(
  (state: RootState) => profileSelectors.getDataField(state, 'certifications'),
  (certifications: List<[string, List<RecordOf<Certification>>]>) => {
    const diversityIndex = certifications?.findIndex(
      ([cat]) => cat === 'diversity'
    )
    return diversityIndex === -1
      ? certifications
      : certifications &&
          certifications.updateIn([diversityIndex], ([cat, certs]) => {
            return List([
              cat,
              certs.filter(cert => !cert.getIn(['info', 'potential']))
            ])
          })
  }
)

type ContainerProps = {
  redirectFrom?: string
} & RouteComponentProps<{ supplierId: string }>

export const mapStateToProps = (state: RootState, props: ContainerProps) => {
  const isInCurrentVet = !!currentVetSuppliersSelector.getVetSupplierBySupplierId(
    state,
    props.match.params.supplierId
  )
  const fromVet = !!props.redirectFrom && props.redirectFrom.includes('vets')
  const domains = profileSelectors.getDataField(state, 'domains')
  const buyerDomains = settingsSelectors.getField(state, 'domains')
  const buyerOrgUnitId = sessionSelectors.getOrgUnitId(state)
  const buyerOrgUnit = orgsSelectors.getById(state, buyerOrgUnitId)
  const contacts = profileSelectors.getContactsForOutreachRequest(state)
  const invitedESGContacts = profileSelectors.getUninvitedContactsForESGRequest(
    state
  )

  const clearbitUrl = domains
    ? `https://logo.clearbit.com/${domains.first()}`
    : ''
  const logoUrl = profileSelectors.getDataField(state, 'logoUrl') || clearbitUrl
  const supplierId = profileSelectors.getDataField(state, 'id')
  const card = cardsSelectors.getBySupplier(state, supplierId)
  const overallRatings = profileSelectors.getOverallRatings(state)
  const spend = relationshipsSelector.getSpendBySupplierId(state, supplierId)
  const attachments = relationshipsSelector.getAttachments(
    state,
    props.match.params.supplierId
  )
  const relationship = relationshipsSelector.getBySupplier(state, supplierId)
  const certifications = getNonPotentialCerts(state)
  const relatedCompany = profileSelectors.getDataField(state, 'relatedCompany')
  const supplierType = profileSelectors.getDataField(state, 'supplierType')
  const supplierRelationship = supplierRelationshipsSelectors.getSupplierRelationshipForCurrentSupplier(
    state
  )
  const validations = supplierRelationshipsSelectors.getCertificationValidation(
    state
  )
  const language = state.get('i18n').locale
  const description = profileSelectors.getDataField(state, 'description')

  const customDescription =
    supplierType === 'basic' &&
    supplierRelationship &&
    supplierRelationship.getIn(['supplierCollaboration', 'description'])

  const hasAccessToDataPortal = sessionSelectors.userHasAccessToApp(
    state,
    'data-portal'
  )
  const surveyResponse = surveySelectors.getEsgSurvey(state)
  const surveySubmitted = !!surveyResponse?.get('attestedDate')
  const createdESGRequest = profileSelectors.getActiveESGSurveyRequest(state)

  return {
    userId: sessionSelectors.getUserId(state),
    isTealbot: sessionSelectors.userHasRole(state, 'tealbot'),
    relationshipId: relationship && relationship.get('id'),
    supplierId: props.match.params.supplierId,
    connected: card && card.get('connected'),
    showAddToVet: !isInCurrentVet && fromVet,
    logoUrl,
    coverUrl: profileSelectors.getDataField(state, 'coverUrl'),
    name: profileSelectors.getDataField(state, 'name'),
    supplierType,
    alternativeNames: profileSelectors.getDataField(state, 'alternativeNames'),
    status: profileSelectors.getDataField(state, 'status'),
    description:
      customDescription || !description || typeof description === 'string'
        ? description
        : description.get(language) ||
          description.get('en') ||
          description
            .toList()
            .filter((d: string) => !!d)
            .first(),
    address: profileSelectors.getPrimaryLocation(state),
    phone: profileSelectors.getPhoneNumber(state),
    websiteUrl: profileSelectors.getDataField(state, 'websiteUrl'),
    linkedInUrl: profileSelectors.getDataField(state, 'linkedInUrl'),
    facebookUrl: profileSelectors.getDataField(state, 'facebookUrl'),
    twitterId: profileSelectors.getDataField(state, 'twitterId'),
    attributes: relationshipsSelector.getSupplierAttributes(
      state,
      props.match.params.supplierId
    ),
    attachments,
    certifications,
    overallRating: overallRatings.averageRating,
    reviewOptions: settingsSelectors.getReviewOptions(state),
    recentPercentile: spend && spend.get('recentPercentile'),
    recentTrend: spend && spend.get('recentTrend'),
    recentAmount: spend && spend.get('recentAmount'),
    hasClientCommunities: communitiesSelectors.getCommunities(state).size > 0,
    relatedCompany: !!(relatedCompany && relatedCompany.size > 0),
    isInCollection: collectionSelectors.isInCollection(
      state,
      props.match.params.supplierId
    ),
    cpiLocation: profileSelectors.getCpiLocation(state),
    websiteWarning: profileSelectors.getDataField(state, 'websiteWarning'),
    diversityProgram: profileSelectors.getDataField(state, 'diversityProgram'),
    buyerDomains,
    buyerOrgUnitId,
    attachmentTypes: settingsSelectors.getAttachmentTypes(state),
    relationshipTypes: settingsSelectors.getRelationshipAttributes(state),
    validations,
    accessControl: sessionSelectors.getAccessControl(state),
    hasAccessToDataPortal,
    contacts,
    invitedESGContacts,
    clientName: buyerOrgUnit.get('name'),
    surveySubmitted,
    createdESGRequest,
    openESGSurveyDialog: !!state.getIn([
      'buyer',
      'supplierProfile',
      'survey',
      'requestESGSurvey'
    ]),
    inviteAnotherESGContactDialog: !!state.getIn([
      'buyer',
      'supplierProfile',
      'survey',
      'inviteAnotherESGContact'
    ]),
    internalSupplierIds: relationshipsSelector.getInternalSupplierId(
      state,
      props.match.params.supplierId
    ),
    isTransferringSpend: state.getIn(['buyer', 'relationships', 'isTransferringSpend'])
  }
}

export default withRouter(
  connect(mapStateToProps, {
    connectToSupplier,
    unfollowSupplier,
    addVetSupplier,
    onRequestProfileUpdate: requestForProfileUpdate,
    onRequestSelfCertify: requestforSelfCertify,
    onRequestESGSurvey: requestForESGSurvey,
    onRequestNewContactForESG: requestNewContactForESGSurvey,
    messageUser,
    onClickNewUser: openAddOutreachContactForm,
    addCollection,
    removeCollection,
    removeRelationship,
    updateSupplierStatus,
    onClickCancel: cancelAddingOutreachContact,
    transferSpend
  })(SupplierProfileHeaderContainer)
)
