import React, { ChangeEvent } from 'react'
import { FormattedMessage } from 'react-intl'

import Label from 'shared/components/Label'
import Select from 'shared/components/Select'

type Props = {
  handleChangeYear: (value: string) => void
  today: Date
  year: string
}

export const YearSelect = ({ handleChangeYear, today, year }: Props) => (
  <>
    <Label htmlFor='year'>
      <FormattedMessage id='UploadSpendDetail.Year' defaultMessage='Year' />
    </Label>
    <Select
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        handleChangeYear(e.currentTarget.value)
      }
      value={year}
      fullWidth
      name='year'
    >
      <FormattedMessage
        id='UploadSpendDetail.SelectYear'
        defaultMessage='Select year'
      >
        {msg => <option value=''>{msg}</option>}
      </FormattedMessage>
      <option value={`${today.getFullYear()}`}>{today.getFullYear()}</option>
      <option value={`${today.getFullYear() - 1}`}>
        {today.getFullYear() - 1}
      </option>
      <option value={`${today.getFullYear() - 2}`}>
        {today.getFullYear() - 2}
      </option>
      <option value={`${today.getFullYear() - 3}`}>
        {today.getFullYear() - 3}
      </option>
      <option value={`${today.getFullYear() - 4}`}>
        {today.getFullYear() - 4}
      </option>
    </Select>
  </>
)
